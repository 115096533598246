import { useCallback, useReducer } from 'react';

import { useNotifications, useSuspensions } from '@personly/libs-providers';
import { deploymentUrl } from '@personly/libs-urls';

import { useStreams } from '../providers/streams';

const initialState = { data: [], count: 0 };

const reducer = (state, action) => {
  switch (action.type) {
    case 'PERSONALS_CLEARED': {
      return { data: [], count: 0 };
    }

    case 'PERSONALS_FETCHED': {
      return {
        ...state,
        data: [...state.data, ...action.data],
        count: action.count,
      };
    }

    case 'PERSONAL_STREAMED': {
      const { id, statusHandle } = action.data;

      const copy = JSON.parse(JSON.stringify(state.data));
      const i = copy.findIndex((personal) => personal.id === id);

      if (i > -1) {
        const shouldRemove = ['CANCELLED', 'FILLED'].includes(statusHandle);
        if (shouldRemove) copy.splice(i, 1);
      } else copy.push(action.data);

      return { ...state, personals: copy };
    }

    default:
      return state;
  }
};

const usePersonals = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { createNotification } = useNotifications();
  const streams = useStreams();
  const { suspensions } = useSuspensions();
  const { createSuspension, removeSuspension } = suspensions;

  const searchPersonals = useCallback(
    async (search, languageHandle, containsMatureContent, { limit, page }) => {
      try {
        createSuspension('SEARCH_PERSONALS');

        if (page === 1) dispatch({ type: 'PERSONALS_CLEARED' });

        const url = deploymentUrl('/agon/v1/guest/searchPersonals');

        url.searchParams.set('paginate.limit', limit);
        url.searchParams.set('paginate.page', page);
        url.searchParams.set('languageHandle', languageHandle);
        url.searchParams.set('containsMatureContent', containsMatureContent);
        url.searchParams.set('search', search);

        const response = await fetch(url);
        const { result, count } = await response.json();

        dispatch({ type: 'PERSONALS_FETCHED', data: result, count });
      } catch (err) {
        const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
        createNotification('ERROR', code);
      } finally {
        removeSuspension('SEARCH_PERSONALS');
      }
    },
    [createNotification, createSuspension, removeSuspension]
  );

  const subscribePersonals = useCallback(
    () =>
      streams.subscribe('APPS-PERSONALS', 'PERSONAL-UPDATED', (data) =>
        dispatch({ type: 'PERSONAL_STREAMED', data })
      ),
    [streams]
  );

  const unsubscribePersonals = useCallback(
    (handlePromise) =>
      handlePromise.then((handle) => streams.unsubscribe(handle)),
    [streams]
  );

  return {
    count: state.count,
    personals: state.data,
    searchPersonals,
    subscribePersonals,
    unsubscribePersonals,
  };
};

export default usePersonals;
