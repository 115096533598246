'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

import { useLocalizedPathname } from '@personly/libs-hooks';

const useRoutes = (languages) => {
  const currentSearchParams = useSearchParams();
  const router = useRouter();

  const localizedPathname = useLocalizedPathname(languages);

  const updateQueryParams = useCallback(
    (nextSearchParams) => {
      const searchParams = new URLSearchParams(currentSearchParams);
      for (const [key, value] of nextSearchParams) {
        searchParams.set(key, value);
      }

      router.push(localizedPathname(`/search?${searchParams.toString()}`));
    },
    [router, currentSearchParams, localizedPathname]
  );

  return { updateQueryParams };
};

export default useRoutes;
