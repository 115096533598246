'use client';

import { useCallback, useMemo } from 'react';

let streamsPromises = {};

const loadStream = (scope, url, onError) => {
  if (!streamsPromises[url]) {
    const options = {
      path: `/${scope}`,
      transports: ['websocket', 'polling'],
    };

    streamsPromises[url] = import('@personly/libs-streams').then(
      ({ default: Streams }) => new Streams(url, options, onError)
    );
  }

  return streamsPromises[url];
};

const useStream = (cookie, onError, scope, url) => {
  const subscribe = useCallback(
    async (channel, event, cb) => {
      const stream = await loadStream(scope, url, onError);
      const accessToken = await cookie.get('access_token');
      if (accessToken) stream.setAccessToken(accessToken);

      return stream.subscribe(channel, event, cb);
    },
    [scope, url, onError, cookie]
  );

  const unsubscribe = useCallback(
    async (handle) => {
      const stream = await loadStream(scope, url, onError);

      return stream.unsubscribe(handle);
    },
    [scope, url, onError]
  );

  const emit = useCallback(
    async (handle, event) => {
      const stream = await loadStream(scope, url, onError);

      stream.emit(handle, event);
    },
    [scope, url, onError]
  );

  const value = useMemo(
    () => ({ subscribe, unsubscribe, emit }),
    [subscribe, unsubscribe, emit]
  );

  return value;
};

export default useStream;
