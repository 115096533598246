'use client';

import React, { createContext, useCallback, useContext } from 'react';

import { useCookie, useStream } from '@personly/libs-hooks';
import { useNotifications } from '@personly/libs-providers';

const StreamsContext = createContext(null);

interface Props {
  children: React.ReactNode;
  scope: string;
  url: string;
}

const StreamsProvider = ({ children, scope, url }: Props) => {
  const cookie = useCookie();
  const { createNotification } = useNotifications();

  const onError = useCallback(
    (err) => {
      const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
      createNotification('ERROR', code);
      console.error(err);
    },
    [createNotification]
  );

  const value = useStream(cookie, onError, scope, url);

  return (
    <StreamsContext.Provider value={value}>{children}</StreamsContext.Provider>
  );
};

function useStreams() {
  const context = useContext(StreamsContext);
  if (!context) throw new Error('useStreams requires a context!');

  return context;
}

export { StreamsProvider, useStreams };
